import React from "react";

import { ShowTranslation } from "../../components";

import getTranslation from "../../common/utils/getTranslation";

import colors from "../../styles/colors";

import emptyHeart from "../../assets/images/icHeartEmpty@3x.png";
import comment from "../../assets/images/icComment18@3x.png";

import { MyFeedDataProps } from "./types";
import { Container, LikeCountCommentCountWrapper, Icon } from "./styles";

const MyFeedData = ({
  totalFeedCount,
  totalLikeCount,
  totalCommentCount,
}: MyFeedDataProps) => {
  return (
    <Container>
      <ShowTranslation
        size={13}
        weight={"m"}
        highlightSize={12}
        highlightWeight={"b"}
        color={colors.gray600}
        style={{ marginBottom: 6 }}
      >
        {getTranslation("MyFeedData.posts", { "{n}": totalFeedCount })}
      </ShowTranslation>

      <LikeCountCommentCountWrapper>
        <ShowTranslation
          size={13}
          weight={"m"}
          color={colors.gray600}
          style={{ alignItems: "baseline", marginRight: 9 }}
        >
          {getTranslation("MyFeedData.totalReactions")}
        </ShowTranslation>
        <Icon marginRight={2} src={emptyHeart} width={16} height={16} />
        <ShowTranslation
          size={13}
          weight={"b"}
          color={colors.gray600}
          style={{ alignItems: "baseline", marginRight: 10 }}
        >
          {totalLikeCount}
        </ShowTranslation>
        <Icon marginRight={2} src={comment} width={16} height={16} />
        <ShowTranslation
          size={13}
          weight={"b"}
          color={colors.gray600}
          style={{ alignItems: "baseline", marginRight: 9 }}
        >
          {totalCommentCount}
        </ShowTranslation>
      </LikeCountCommentCountWrapper>
    </Container>
  );
};

export default MyFeedData;
