import { Fragment } from "react";

import { Reset } from "styled-reset";
import GlobalStyles from "./styles/globalStyles";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AppGlobalHandlers } from "./components";

import {
  MainFeed,
  FeedWrite,
  AuthError,
  FourOFourError,
  FeedWriteNotice,
  Login,
  BookmarkList,
} from "./pages";

import {
  ServerMaintenanceModal,
  FeedDetailModal,
  FeedReportDetailModal,
  BookmarkErrorModal,
} from "./systems";

function App() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // development code
    // 임시 삭제 해야함
    // window.connectingApp = {
    //   authToken: "273861230112eb1f35559a79637785f2b314dae7",
    //   phoneLanguage: "ko",
    //   uuid: "679f0d6d8bae483bb73e82a888df8a58",
    // };
  } else {
    // production code
  }
  return (
    <Fragment>
      <Reset />
      <GlobalStyles />

      <Router>
        <AppGlobalHandlers />
        <ServerMaintenanceModal />
        <BookmarkErrorModal />
        <Routes>
          <Route path="*" element={<FourOFourError />} />

          <Route path="/" element={<MainFeed />}>
            <Route path="feed_detail/:id" element={<FeedDetailModal />} />
            <Route
              path="feed_report_detail/:id"
              element={<FeedReportDetailModal />}
            />
            <Route path="bookmark_list" element={<BookmarkList />} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/write" element={<FeedWrite />} />
          <Route path="/write_notice" element={<FeedWriteNotice />} />

          <Route path="/auth_error" element={<AuthError />} />
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;
