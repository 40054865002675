import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { DeliverFeedABTestValueData } from "../../common/utils/ReactNativeWebview/appEventListenerService/types";

type AbTestKeyType = {
  ab_test_feed_bookmark?: string;
};

const initialState: AbTestKeyType = {};

export const abTestDataSlice = createSlice({
  name: "abTestData",
  initialState,
  reducers: {
    setABTestData: (
      state,
      action: PayloadAction<DeliverFeedABTestValueData>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setABTestData } = abTestDataSlice.actions;

export const selectABTestData = (state: RootState) => state.abTestData;

export default abTestDataSlice.reducer;
