import styled from "styled-components";
import Modal from "styled-react-modal";
import colors from "../../styles/colors";

export const ModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${"rgba(0, 0 , 0, 0.7)"};
  z-index: 1500;
`;

export const StyledModal = Modal.styled`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0px 37px;
  background-color: ${colors.white};
  border-radius: 8px;
`;

export const ActionButton = styled.button`
  background-color: ${colors.Blue700};
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 100%;
  height: 54px;
  margin-top: 24px;
`;

export const Action = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: ${colors.white};
`;

export const Message = styled.div`
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: ${colors.gray900};
  align-items: center;
`;
