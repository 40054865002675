import React from "react";

import BookmarkIcon from "../../assets/images/Bookmark_20_Line_1@3x.png";

import { Container, Icon } from "./styles";

interface IProps {
  onClick: () => void;
}

const BookmarkButton = ({ onClick }: IProps) => {
  return (
    <Container onClick={onClick} onDragStart={event => event.preventDefault()}>
      <Icon src={BookmarkIcon} width={20} height={20} />
    </Container>
  );
};

export default BookmarkButton;
