import styled from "styled-components";

import errorIcon from "../../assets/images/ic60ErrorSign@3x.png";
import colors from "../../styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  height: calc(
    100vh -
      ${(props: { subtractHeight?: number }) => props.subtractHeight || 0}px
  );
  align-items: center;
  justify-content: center;
`;

export const ErrorIcon = styled.img.attrs({
  src: `${errorIcon}`,
})`
  width: 60px;
  height: 60px;
  margin-bottom: 17px;
`;
