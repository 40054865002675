import React from "react";

import ReactLoading from "react-loading";

import { LoadingContainer } from "./styles";

import colors from "../../styles/colors";

const LoadingMoreIndicator = ({
  isLoadingMore,
}: {
  isLoadingMore: boolean;
}) => {
  if (isLoadingMore) {
    return (
      <LoadingContainer>
        <ReactLoading
          height={18}
          width={18}
          color={colors.gray500}
          type={"spokes"}
        />
      </LoadingContainer>
    );
  }

  return null;
};

export default LoadingMoreIndicator;
