import { isValidElement } from "react";

import getTranslation from "../../common/utils/getTranslation";

import colors from "../../styles/colors";
import ShowTranslation from "../ShowTranslation";

import { ToolTipProps } from "./types";
import { Close, Container, Root, BottomTriangle, TopTriangle } from "./styles";

const ToolTip = ({
  title,
  content,
  isVisible,
  onClose,
  toolTipPosition,
  triangleType,
  triangleHorizontalPosition,
}: ToolTipProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Root style={{ ...toolTipPosition }}>
      {triangleType === "TOP" && (
        <TopTriangle style={{ ...triangleHorizontalPosition }} />
      )}
      <Container>
        {title && (
          <ShowTranslation
            style={{ marginBottom: 3 }}
            weight="b"
            size={13}
            color={colors.white}
          >
            {title}
          </ShowTranslation>
        )}
        {isValidElement(content) ? (
          content
        ) : (
          <ShowTranslation weight="r" size={12} color={colors.white}>
            {content}
          </ShowTranslation>
        )}
        <Close onClick={onClose} />
      </Container>
      {triangleType === "BOTTOM" && (
        <BottomTriangle style={{ ...triangleHorizontalPosition }} />
      )}
    </Root>
  );
};

ToolTip.defaultProps = {
  triangleType: "TOP",
};

export default ToolTip;
