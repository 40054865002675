import styled from "styled-components";
import colors from "../../styles/colors";

interface IconProps {
  width?: number;
  height?: number;
  marginRight?: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: ${colors.gray200};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 14px 16px;
  margin: 0px 0px 0px 10px;
`;

export const Icon = styled.img`
  width: ${(props: IconProps) => props.width || 12}px;
  height: ${(props: IconProps) => props.height || 12}px;
  margin-right: ${(props: IconProps) => props.marginRight || 0}px;
`;
