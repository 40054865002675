import styled from "styled-components";

interface IconProps {
  width?: number;
  height?: number;
  marginRight?: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LikeCountCommentCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Icon = styled.img`
  width: ${(props: IconProps) => props.width || 12}px;
  height: ${(props: IconProps) => props.height || 12}px;
  margin-right: ${(props: IconProps) => props.marginRight || 0}px;
`;
