import { ChangeEvent, useEffect, useState } from "react";

import { SoundPlayer, ShowTranslation, CommonHeader } from "../../components";

import {
  Wrapper,
  WriteSetupWrapper,
  DropdownWrapper,
  DropdownItem,
  DropdownText,
  PublicIcon,
  PrivateIcon,
  ChevronDownIcon,
  UploadWrapper,
  PictureIcon,
  GifIcon,
  RecordIcon,
  PictureDisabledIcon,
  GifDisabledIcon,
  RecordDisabledIcon,
  ContentsWrapper,
  StyledTextarea,
  CancleButtonWrapper,
  CancelButton24Icon,
  CancelButton18Icon,
  UploadImageWrapper,
  UploadImage,
  UploadImageFile,
  MarginRightDiv,
  GifWrapper,
  Gif,
  GifFile,
  GifWatermark,
  SoundPlayerWrapper,
  StyledTitleInput,
} from "./styles";

import { createFeedNotice } from "../../features/feedWrite/feedWriteThunks";
import { useAppDispatch } from "../../common/hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import useInput from "../../common/hooks/useInput";
import useModal from "../../common/hooks/useModal";
import { unwrapResult } from "@reduxjs/toolkit";
import { TwoButtonModal, OneButtonModal } from "../../components";

import { MessageFromAppCallbacks } from "../../common/utils/ReactNativeWebview/appEventListenerService/types";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";
import getTranslation from "../../common/utils/getTranslation";

import colors from "../../styles/colors";

const PUBLIC = "PUBLIC";
const PRIVATE = "PRIVATE";

type FileType = "IMAGE" | "AUDIO" | "GIF" | "NORMAL";

type Files = Array<{ url: string }>;

const inputValidator = (text: string) => {
  if (text.trim().length >= 5) {
    return true;
  }
  return false;
};

const titleValidator = (text: string) => {
  if (text.trim().length > 3) {
    return true;
  }
  return false;
};

const FeedWriteNotice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    value: text,
    onChangeText,
    isValidInput,
  } = useInput({
    initialValue: "",
    validator: inputValidator,
  });

  const {
    value: title,
    onChangeText: onChangeTitle,
    isValidInput: isValidTitle,
  } = useInput({
    initialValue: "",
    validator: titleValidator,
  });

  const {
    isVisible: isCancelWriteFeedModalVisible,
    showModal: showCancelWriteFeedModal,
    closeModal: closeCancelWriteFeedModal,
  } = useModal();

  const {
    isVisible: isWriteErrorModalVisible,
    showModal: showWriteErrorModal,
    closeModal: closeWriteErrorModal,
  } = useModal();

  const [files, setFiles] = useState<Files>([]);
  const [fileType, setFileType] = useState<FileType>("NORMAL");
  const [audioDuration, setAudioDuration] = useState(0);
  const [loading, setLoading] = useState("NONE");
  const [error, setError] = useState(null);

  const onChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeText(event.target.value);
  };

  const onChangeTitleInputArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeTitle(event.target.value);
  };

  const deleteSelectedImage = (index: number) => {
    setFiles(prevImages => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);

      if (updatedImages.length === 0) {
        setFileType("NORMAL");
      }
      return updatedImages;
    });
  };

  const deleteAudio = () => {
    setFiles([]);
    setAudioDuration(0);
    setFileType("NORMAL");
  };

  const deleteGif = () => {
    setFiles([]);
    setFileType("NORMAL");
  };

  const scrollToBottom = () => () => {
    let docScrollingElement = document.scrollingElement || document.body;
    docScrollingElement.scrollTop = docScrollingElement.scrollHeight;
  };

  const navigateToMain = () => {
    navigate(-1);
  };

  const onClickUpload = (fileType: FileType) => {
    setFileType(fileType);
    ReactNativeWebview.postMessageToApp({
      type: "REQUEST_FILE_UPLOAD",
      data: {
        fileType,
      },
    });
  };

  const writeFeed = async () => {
    if ((isValidTitle && isValidInput) || files.length > 0) {
      try {
        ReactNativeWebview.postMessageToApp({
          type: "SHOW_LOADING_INDICATOR",
          data: {
            isLoading: true,
          },
        });

        const writeResponse = await dispatch(
          createFeedNotice({
            title: title,
            text: text,
            access: PUBLIC,
            contentType: fileType,
            contentURL: files,
            contentDuration: audioDuration,
          })
        );

        const unwrappedWriteResponse = unwrapResult(writeResponse);

        if (
          unwrappedWriteResponse.res_code === "SUCCESS_CREATE_NOTICE_FEED_POST"
        ) {
          setLoading("NONE");
          setTimeout(() => {
            navigateToMain();
            ReactNativeWebview.postMessageToApp({
              type: "SHOW_LOADING_INDICATOR",
              data: {
                isLoading: false,
              },
            });
          }, 500);
        }
      } catch (error) {
        showWriteErrorModal();
        setLoading("NONE");
      }
    }
  };

  useEffect(() => {
    const handleDeliveredFiles: MessageFromAppCallbacks["DELIVER_UPLOADED_FILES"] =
      data => {
        if (fileType === "GIF") {
          setFiles([{ url: data.files[0].url }]);
          return;
        }

        if (fileType === "AUDIO") {
          setFiles([{ url: data.files[0].url }]);
          setAudioDuration(data.files[0].duration ? data.files[0].duration : 0);
          return;
        }

        if (fileType === "IMAGE") {
          setFiles(data.files);
          return;
        }
      };

    return () => {
      ReactNativeWebview.removeAppEventListener(
        "DELIVER_UPLOADED_FILES",
        handleDeliveredFiles
      );
    };
  }, [fileType]);

  const renderWriteSetup = () => {
    return (
      <WriteSetupWrapper>
        <UploadWrapper>
          {files.length > 0 ||
          !ReactNativeWebview.isReactNativeWebviewAvailable ? (
            <PictureDisabledIcon />
          ) : (
            <PictureIcon onClick={() => onClickUpload("IMAGE")} />
          )}
          {files.length > 0 ||
          !ReactNativeWebview.isReactNativeWebviewAvailable ? (
            <GifDisabledIcon />
          ) : (
            <GifIcon onClick={() => onClickUpload("GIF")} />
          )}
          {files.length > 0 ||
          !ReactNativeWebview.isReactNativeWebviewAvailable ? (
            <RecordDisabledIcon />
          ) : (
            <RecordIcon onClick={() => onClickUpload("AUDIO")} />
          )}
        </UploadWrapper>
      </WriteSetupWrapper>
    );
  };

  const renderContents = () => {
    return (
      <ContentsWrapper>
        <StyledTitleInput
          autoFocus
          minRows={1}
          value={title}
          onChange={onChangeTitleInputArea}
          spellCheck="false"
          placeholder="재목 을 작성해주세요"
        />

        <StyledTextarea
          autoFocus
          minRows={1}
          value={text}
          onChange={onChangeTextArea}
          onHeightChange={scrollToBottom}
          spellCheck="false"
          placeholder="최소 5자 이상 입력해 주세요. 연락처 교환 등 부적절한 글은 삭제될 수 있으며, 등록한 글은 수정과 삭제가 어려우니 참고해 주세요."
        />
      </ContentsWrapper>
    );
  };

  const renderFiles = () => {
    const renderImages = () => {
      if (fileType === "IMAGE" && files.length > 0) {
        return (
          <UploadImageWrapper>
            {files.map((image, index: number) => (
              <UploadImage key={`image-id-${image.url}`}>
                <CancelButton24Icon
                  onClick={() => deleteSelectedImage(index)}
                />
                <UploadImageFile src={image.url} />
              </UploadImage>
            ))}
            <MarginRightDiv />
          </UploadImageWrapper>
        );
      }
    };
    const renderGif = () => {
      if (fileType === "GIF" && files.length > 0) {
        return (
          <GifWrapper>
            <Gif>
              <CancelButton24Icon onClick={deleteGif} />
              <GifFile src={files[0].url} />
            </Gif>
            <GifWatermark />
          </GifWrapper>
        );
      }
    };

    const renderSoundPlayer = () => {
      if (fileType === "AUDIO" && files.length > 0) {
        return (
          <SoundPlayerWrapper>
            <CancleButtonWrapper>
              <CancelButton18Icon onClick={deleteAudio} />
            </CancleButtonWrapper>
            <SoundPlayer
              type={"FULL"}
              audioUrl={files[0].url}
              initDuration={audioDuration}
            />
          </SoundPlayerWrapper>
        );
      }
    };

    return (
      <>
        {renderImages()}
        {renderSoundPlayer()}
        {renderGif()}
      </>
    );
  };

  return (
    <Wrapper>
      <TwoButtonModal
        title={"작성을 취소하시겠습니까?"}
        content={"작성 취소를 누르면 현재 글은 저장되지 않아요."}
        action={"계속 작성하기"}
        secondAction={"작성 취소"}
        isVisible={isCancelWriteFeedModalVisible}
        onAction={closeCancelWriteFeedModal}
        onSecondAction={navigateToMain}
        onClose={closeCancelWriteFeedModal}
        canBackdropPress={true}
      />

      <OneButtonModal
        title={getTranslation("FeedWrite.writeFailed") as string}
        isVisible={isWriteErrorModalVisible}
        onAction={closeWriteErrorModal}
        onClose={closeWriteErrorModal}
        action={getTranslation("Common.ok") as string}
        canBackdropPress={true}
      />
      <CommonHeader
        headerLeft="close"
        headerTitle="글쓰기"
        headerRight={
          <ShowTranslation
            style={{
              backgroundColor:
                (isValidTitle && isValidInput) || files.length > 0
                  ? colors.Blue700
                  : colors.gray300,
              borderRadius: 14,
              padding: "4px 12px",
            }}
            size={15}
            weight={"b"}
            color={colors.white}
          >
            작성
          </ShowTranslation>
        }
        onClickLeft={showCancelWriteFeedModal}
        onClickRight={writeFeed}
      />
      {renderWriteSetup()}
      {renderContents()}
      {renderFiles()}
    </Wrapper>
  );
};

export default FeedWriteNotice;
