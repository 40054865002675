import styled from "styled-components";
import { ImageSourceButton } from "../../styles/commonStyles";
import ShowTranslation from "../../components/ShowTranslation";
import TextAreaAutosize from "react-textarea-autosize";

import chevronDownIcon from "../../assets/images/ic9ChevronDownCntDarkgreen@3x.png";
import publicIcon from "../../assets/images/ic14GlobalCnTdarkgreen@3x.png";
import privateIcon from "../../assets/images/ic14FollowingCnTdarkgreen@3x.png";

import pictureGray from "../../assets/images/btn20PictureGray900@3x.png";
import pictureLightGray from "../../assets/images/btn20PictureLightgray@3x.png";
import gifGray from "../../assets/images/btn20GifGray900@3x.png";
import gifLightGray from "../../assets/images/btn20GifLightgray@3x.png";
import recordGray from "../../assets/images/btn20RecordGray900@3x.png";
import recordLightGray from "../../assets/images/btn20RecordLightgray@3x.png";
import cancelButton24 from "../../assets/images/btnCancelCross24@3x.png";
import cancelButton18 from "../../assets/images/btnCancelCircle18@3x.png";
import gifWatermark from "../../assets/images/imgGiphyLogo@3x.png";

import colors from "../../styles/colors";
import fontWeight from "../../styles/fontWeights";

interface IProps {
  right: number;
  top: number;
}

export const Wrapper = styled.div``;

export const WriteSetupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 0 20px;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  left: 20px;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.CNTMidGreen};
  border-radius: 4px;
  border: 1px solid rgba(0, 219, 199, 0.16);
  padding: 5px 6px 5px 6px;
  min-width: 105px;
`;

export const DropdownText = styled(ShowTranslation).attrs({
  size: 12,
  weight: "m",
  color: colors.CNTDarkGreen,
})`
  margin-left: 4px;
  margin-right: 4px;
`;

export const PublicIcon = styled.img.attrs({
  src: publicIcon,
})`
  width: 14px;
  height: 14px;
`;

export const PrivateIcon = styled.img.attrs({
  src: privateIcon,
})`
  width: 14px;
  height: 14px;
`;

export const ChevronDownIcon = styled.img.attrs({
  src: chevronDownIcon,
})`
  width: 9px;
  height: 9px;
  margin-left: auto;
`;

export const UploadWrapper = styled.div`
  position: relative;
  padding-top: 4px;
  button:nth-of-type(2) {
    margin-left: 18px;
    margin-right: 18px;
  }
`;

export const PictureIcon = styled(ImageSourceButton).attrs({
  source: pictureGray,
  width: 20,
  height: 20,
})``;

export const PictureDisabledIcon = styled(ImageSourceButton).attrs({
  source: pictureLightGray,
  width: 20,
  height: 20,
})``;

export const GifIcon = styled(ImageSourceButton).attrs({
  source: gifGray,
  width: 20,
  height: 20,
})``;

export const GifDisabledIcon = styled(ImageSourceButton).attrs({
  source: gifLightGray,
  width: 20,
  height: 20,
})``;

export const RecordIcon = styled(ImageSourceButton).attrs({
  source: recordGray,
  width: 20,
  height: 20,
})``;

export const RecordDisabledIcon = styled(ImageSourceButton).attrs({
  source: recordLightGray,
  width: 20,
  height: 20,
})``;

export const ContentsWrapper = styled.div`
  padding: 16px 20px 0 20px;
`;

export const StyledTitleInput = styled(TextAreaAutosize)`
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  height: 50px;
  font-size: 16px;
  line-height: 21px;
  overflow-y: scroll;
  padding: 0;
  color: ${colors.gray900};
  margin-bottom: 10px;
  border-bottom: 1px solid ${colors.gray500};

  ::placeholder {
    font-family: inherit;
    font-size: 16px;
    line-height: 21px;
    font-weight: ${fontWeight.regular};
    color: ${colors.gray500};
  }
`;

export const StyledTextarea = styled(TextAreaAutosize)`
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 21px;
  overflow-y: scroll;
  padding: 0;
  color: ${colors.gray900};

  ::placeholder {
    font-family: inherit;
    font-size: 16px;
    line-height: 21px;
    font-weight: ${fontWeight.regular};
    color: ${colors.gray500};
  }
`;

export const CancelButton24Icon = styled(ImageSourceButton).attrs({
  source: cancelButton24,
  width: 24,
  height: 24,
})`
  position: absolute;
  right: 6px;
  top: 6px;
`;

export const CancleButtonWrapper = styled.div`
  padding-bottom: 6px;
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton18Icon = styled(ImageSourceButton).attrs({
  source: cancelButton18,
  width: 18,
  height: 18,
})``;

export const MarginRightDiv = styled.div`
  min-width: 20px;
`;

export const UploadImageWrapper = styled.div`
  display: flex;
  padding: 20px 0 0 12px;
  width: 100vw;
  height: 200px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const UploadImage = styled.div`
  position: relative;
  height: 180px;
  margin-left: 8px;
`;

export const UploadImageFile = styled.img`
  height: 100%;
  border-radius: 6px;
`;

export const GifWrapper = styled.div`
  padding: 20px;
`;

export const Gif = styled.div`
  position: relative;
  width: 180px;
  height: 180px;
`;

export const GifFile = styled.img`
  border-radius: 6px;
  width: 180px;
  height: 180px;
  object-fit: cover;
  aspect-ratio: 1;
`;

export const GifWatermark = styled.img.attrs({
  src: gifWatermark,
})`
  width: 119px;
  height: 18px;
  margin-top: 6px;
`;

export const SoundPlayerWrapper = styled.div`
  width: 100%;
  padding: 20px 20px 0 20px;
`;
