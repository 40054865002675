import React, { useState, useEffect } from "react";

import useScroll from "../../common/hooks/useScroll";
import { ScrollToTopIcon } from "./styles";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { scrollToTop, scrollY } = useScroll({ throttleValue: 100 });

  useEffect(() => {
    if (scrollY > 600) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [scrollY]);

  return <ScrollToTopIcon onClick={scrollToTop} visible={isVisible} />;
};

export default ScrollToTopButton;
