import styled from "styled-components";
import colors from "../../styles/colors";
import fontWeight from "../../styles/fontWeights";
import ShowTranslation from "../ShowTranslation";

import {
  ImageSourceButton,
  ImageSourceButtonProps,
} from "../../styles/commonStyles";

interface LikeCommentWrapperProps {
  marginRight?: number;
}

interface ProfileExchangeWrapperProps extends LikeCommentWrapperProps {}

interface IconProps {
  width?: number;
  height?: number;
  marginRight?: number;
}
interface ImageButtonProps extends ImageSourceButtonProps {
  marginRight?: number;
}

const FeedItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
`;

const FeedItemHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 16px;
  margin-bottom: 10px;
`;

const FeedUserInfoWrapper = styled.div`
  flex-grow: 1;
  height: "100%";
`;

const FeedStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ManagerButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
`;

const ManagerButton = styled.div`
  display: flex;
  padding: 2px;
  border-radius: 10px;

  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;

  width: 50px;
  border: 1px solid gray;
`;

const FeedContentWrapper = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 16px;
  border-bottom: 0.5px solid ${colors.gray300};
`;

const FeedTextWrapper = styled.div``;

const FeedFileWrapper = styled.div`
  margin-top: 6px;
`;

const LegacyFeedCallRoomWrapper = styled.div`
  border-radius: 8px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray100};
  padding: 20px 0px 20px 0px;
`;

const FeedText = styled.span`
  max-height: ${(props: { isTruncate?: boolean; isHyperlink?: boolean }) =>
    props.isTruncate ? `200px` : `none`};
  display: ${(props: { isHyperlink?: boolean }) =>
    props.isHyperlink ? `inline` : `flex`};
  line-break: anywhere;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  line-height: 20px;
  font-weight: ${fontWeight.regular};
  font-size: 15px;
  flex-wrap: wrap;
`;

const Icon = styled.img`
  width: ${(props: IconProps) => props.width || 12}px;
  height: ${(props: IconProps) => props.height || 12}px;
  margin-right: ${(props: IconProps) => props.marginRight || 0}px;
`;

const ImageButton = styled(ImageSourceButton)`
  margin-right: ${(props: ImageButtonProps) => props.marginRight || 0}px;
`;

const FeedItemBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeedItemActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  padding-right: 20px;
  padding-left: 20px;
`;

const LikeCommentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ marginRight }: LikeCommentWrapperProps) =>
    marginRight || 0}px;
`;

const ProfileExchangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ marginRight }: ProfileExchangeWrapperProps) =>
    marginRight || 0}px;
`;

const ThickBorder = styled.div`
  height: 10px;
  background-color: ${colors.gray200};
`;

const HyperLink = styled.span`
  color: ${colors.linkBlue};
  line-height: 20px;
  font-weight: ${fontWeight.regular};
  font-size: 15px;
`;

export {
  FeedItemContainer,
  FeedItemHeaderWrapper,
  FeedUserInfoWrapper,
  FeedContentWrapper,
  FeedStatusWrapper,
  FeedItemBottomContainer,
  FeedItemActionWrapper,
  LikeCommentWrapper,
  ProfileExchangeWrapper,
  FeedTextWrapper,
  FeedFileWrapper,
  FeedText,
  Icon,
  ImageButton,
  ThickBorder,
  HyperLink,
  LegacyFeedCallRoomWrapper,
  ManagerButtonWrapper,
  ManagerButton,
};
