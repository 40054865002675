import styled from "styled-components";
import colors from "../../styles/colors";
import fontWeight from "../../styles/fontWeights";

import { ImageSourceButton } from "../../styles/commonStyles";

const FullPlayerContainer = styled.div`
  display: flex;
  height: 54px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 6px;
  background: ${colors.gray100};
  border: 0.5px solid ${colors.gray200};
  align-items: center;
  justify-content: center;
`;

const ProgressBar = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  flex-grow: 1;
  margin-left: 16px;
  height: 3px;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: background 0.1s ease;
  background: ${colors.gray300};
  background: ${(props: { progressPercent: string }) =>
    `-webkit-gradient(linear, 0% 0%, 100% 0%, 
    color-stop(
    ${props.progressPercent}, ${colors.neonGreen}), 
    color-stop(
    ${props.progressPercent}, ${colors.gray300}))`};

  background: ${(props: { progressPercent: string }) =>
    `-moz-gradient(linear, 0% 0%, 100% 0%, 
    color-stop(
    ${props.progressPercent}, ${colors.neonGreen}), 
    color-stop(
    ${props.progressPercent}, ${colors.gray300}))`};

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    outline: none;
    border: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    background: ${colors.neonGreen};
    cursor: pointer;
    border: unset;
    padding: unset;
    margin: 0px;
    height: 9px;
    width: 9px;
    border-radius: 4.5px;
  }

  &::-moz-slider-thumb {
    -moz-appearance: none;
    background: ${colors.neonGreen};
    cursor: pointer;
    border: unset;
    padding: unset;
    margin: unset;
    margin-top: 0px;
    height: 9px;
    width: 9px;
    border-radius: 4.5px;
  }
`;

const RemainTimer = styled.div`
  font-size: 12px;
  margin-left: 6px;
  font-weight: ${fontWeight.medium};
  color: ${colors.gray800};
`;

const AudioControlButton = styled(ImageSourceButton)``;

const MiniPlayerContainer = styled.div`
  display: flex;
  width: 79px;
  height: 30px;
  border: 1px solid ${colors.gray600};
  border-radius: 35px;
  justify-content: center;
  align-items: center;
`;

export {
  RemainTimer,
  ProgressBar,
  AudioControlButton,
  FullPlayerContainer,
  MiniPlayerContainer,
};
