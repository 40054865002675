const colors = {
  PRIMARY: "rgb(18, 196, 174)",
  PRIMARY2: "rgb(120,55,251)",
  PRIMARY_GRADIENT: ["rgb(19, 206, 171)", "rgb(41, 151, 210)"],
  PRIMARY_GRADIENT2: ["rgb(36, 225, 208)", "rgb(52, 184, 248)"],
  PRIMARY_LIME: "rgb(12, 137, 125)",
  PRIMARY_GREEN: "rgb(2, 87, 78)",
  PRIMARY_LIGHT_YELLOW: "rgb(255, 246, 205)",
  gray900: "rgb(34, 34, 34)",
  gray800: "rgb(51, 51, 51)",
  gray700: "rgb(102, 102, 102)",
  gray600: "rgb(133, 133, 133)",
  gray500: "rgb(170, 170, 170)",
  gray400: "rgb(204, 204, 204)",
  gray300: "rgb(230, 230, 230)",
  gray200: "rgb(242, 242, 242)",
  gray100: "rgb(248, 248, 248)",
  white: "rgb(255, 255, 255)",
  black: "rgb(0, 0, 0)",
  neonGreen: "rgb(0, 219, 199)",
  CNTMint: "rgb(0, 194, 176)",
  Blue700: "rgb(61, 103, 252)",
  CNTDarkGreen: "rgb(0, 156, 142)",
  CNTMidGreen: "rgb(214, 248, 242)",
  CNTLightGreen: "rgb(238, 251, 250)",
  CNTPurple: "rgb(108, 77, 255)",
  lightPurple: "rgb(241, 239, 255)",
  callingGreen: "rgb(52, 199, 89)",
  notiRed: "rgb(255, 86, 53)",
  bannerBlue: "rgb(57, 108, 233)",
  toastGray: "rgb(238, 239, 241)",
  linkBlue: "rgb(37, 129, 192)",
  navy01: "rgb(26, 34, 53)",
};

export default colors;
