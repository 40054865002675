import styled from "styled-components";
import colors from "../../styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: ${colors.white};
`;
