import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "../../translation/i18n";

import { MessageFromAppCallbacks } from "../../common/utils/ReactNativeWebview/appEventListenerService/types";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

import useServerMaintenance from "../../common/hooks/useServerMaintenance";
import useAuth from "../../common/hooks/useAuth";
import {
  configureAxiosAuthorizationHeader,
  configureAxiosUuidHeader,
} from "../../common/utils/axios";
import useUserControl from "../../common/hooks/useUserControl";
import { fetchReportDefinitions } from "../../features/report/reportThunks";
import { setToolTips } from "../../features/globalAppState/globalAppStateSlice";

import { useAppDispatch } from "../../common/hooks/reduxHooks";
import { setAudioPlayerGlobalState } from "../../features/globalAppState/globalAppStateSlice";
import dayjs from "dayjs";
import { setABTestData } from "../../features/abTestData/abTestDataSlice";

const AppGlobalHandlers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { pathname } = location;

  const {
    authorizeUser,
    isAuthorized,
    isAuthError,
    userData,
    setUserAuthError,
  } = useAuth();

  const { getUserControl } = useUserControl();

  const { getServerStatus } = useServerMaintenance();

  const configureLocale = () => {
    //웹 실행시 앱에서 넘어오는 언어로 수정
    if (window.connectingApp) {
      const translationGetters = {
        ko: () => require("dayjs/locale/ko"),
        ja: () => require("dayjs/locale/ja"),
        en: () => require("dayjs/locale/en"),
        ms: () => require("dayjs/locale/ms"),
        zh: () => require("dayjs/locale/zh"),
      };

      translationGetters[window.connectingApp.phoneLanguage]();
      dayjs.locale(window.connectingApp.phoneLanguage);
      i18n.changeLanguage(window.connectingApp.phoneLanguage);
    }
  };

  const configureAxiosHeaders = () => {
    //api header token 값 지정
    if (window.connectingApp) {
      configureAxiosAuthorizationHeader(window.connectingApp.authToken);
      configureAxiosUuidHeader(window.connectingApp.uuid);
    }
  };

  useEffect(() => {
    configureLocale();
    configureAxiosHeaders();
  }, []);

  useEffect(() => {
    const handleStopAudioPlayer: MessageFromAppCallbacks["STOP_AUDIO_PLAYER"] =
      () => {
        dispatch(setAudioPlayerGlobalState("STOP_PLAYER"));
      };

    const handleToolTipSeenStatus: MessageFromAppCallbacks["DELIVER_TOOLTIPS_SEEN_STATUS"] =
      data => {
        dispatch(
          setToolTips({
            key: "PROFILE_EXCHANGE_VISIBLE",
            isVisible: !data.PROFILE_EXCHANGE_SEEN,
          })
        );
      };

    const handleRemoteConfigValue: MessageFromAppCallbacks["DELIVER_FEED_AB_TEST_VALUE"] =
      data => {
        if (data) {
          dispatch(setABTestData(data));
        }
      };

    ReactNativeWebview.addAppEventListener(
      "STOP_AUDIO_PLAYER",
      handleStopAudioPlayer
    );

    ReactNativeWebview.addAppEventListener(
      "DELIVER_TOOLTIPS_SEEN_STATUS",
      handleToolTipSeenStatus
    );

    ReactNativeWebview.addAppEventListener(
      "DELIVER_FEED_AB_TEST_VALUE",
      handleRemoteConfigValue
    );

    return () => {
      ReactNativeWebview.removeAppEventListener(
        "STOP_AUDIO_PLAYER",
        handleStopAudioPlayer
      );

      ReactNativeWebview.removeAppEventListener(
        "DELIVER_TOOLTIPS_SEEN_STATUS",
        handleToolTipSeenStatus
      );

      ReactNativeWebview.removeAppEventListener(
        "DELIVER_FEED_AB_TEST_VALUE",
        handleRemoteConfigValue
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (userData && userData.black_type) {
      ReactNativeWebview.postMessageToApp({
        type: "SHOW_ACCOUNT_SUSPENDED_MODAL",
      });
    }
  }, [userData]);

  useEffect(() => {
    //웹 주소 링크가 바뀔떼마다 서버 상테 확인하기
    if (location) {
      getServerStatus();
    }
  }, [location]);

  useEffect(() => {
    if (isAuthorized && userData && window.connectingApp) {
      dispatch(
        fetchReportDefinitions({
          language_locale: window.connectingApp.phoneLanguage,
          country_code: userData.country,
        })
      );
    }
  }, [isAuthorized, userData, dispatch]);

  useEffect(() => {
    if (location && isAuthorized) {
      getUserControl("FEED");
    }
  }, [location, isAuthorized]);

  useEffect(() => {
    //인증 되어있지 않으면 인증 과정 실행
    if (!isAuthorized) {
      if (window.connectingApp) {
        authorizeUser(window.connectingApp.authToken);
      } else {
        setUserAuthError();
      }
    }

    // 유저 auth 인징 실페 일시 넘기도록 임시 로 관리 로 넘김 따로 화면 만들거임
    if (isAuthError && pathname !== "/login") {
      navigate("/auth_error", { replace: true });
    }
  }, [isAuthorized, isAuthError, pathname]);

  return <div></div>;
};

export default AppGlobalHandlers;
