import styled from "styled-components";
import colors from "../../styles/colors";
import closeImg from "../../assets/images/Close_12_White.png";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10000;
`;

export const Container = styled.div`
  min-width: 232px;
  max-width: 252px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  padding: 12px 28px 12px 12px;
  border-radius: 8px;
  position: relative;
  box-sizing: "border-box";
`;

export const BottomTriangle = styled.div`
  width: 0px;
  height: 0px;
  border-top: 7px solid rgba(0, 0, 0, 0.7);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: relative;
  bottom: 0px;
`;

export const TopTriangle = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: 7px solid rgba(0, 0, 0, 0.7);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: relative;
  top: 0px;
`;

export const Close = styled.img.attrs({ src: closeImg })`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 12px;
  right: 12px;
`;
