import { useState, useEffect, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import blueBanner from "../../assets/images/Banner_FeedNotice_49@3x.png";
import orangeBanner from "../../assets/images/Banner_Notice_49@3x.png";
import profileExchangeBanner from "../../assets/images/Banner_Profile_49@3x.png";

import {
  BannerContainer,
  IndexIndicator,
  BannerImage,
  BannerTextWrapper,
  BannerWrapper,
} from "./style";
import colors from "../../styles/colors";

import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

import { ShowTranslation } from "..";

import { IFeedItem } from "../../common/types/commonTypes";
import getTranslation from "../../common/utils/getTranslation";

interface IProps {
  noticeList: Array<IFeedItem>;
  onClickNoticeFeed: (item: IFeedItem) => void;
}

const BannerNotice = ({ noticeList, onClickNoticeFeed }: IProps) => {
  const bannerNotifications = [
    {
      image: blueBanner,
      text: getTranslation("BannerNotice.feedRuleBanner"),
      textColor: colors.gray900,
      onClick: () =>
        ReactNativeWebview.postMessageToApp({
          type: "NAVIGATE_TO_FEED_RULE_WEBVIEW",
        }),
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(1);

  const [bannerList, setBannerList] = useState<Array<any>>(bannerNotifications);

  useEffect(() => {
    if (noticeList.length > 0) {
      const recentFiveNotice = noticeList.slice(0, 3);

      setBannerList([...recentFiveNotice, ...bannerNotifications]);
    }
  }, [noticeList]);

  const onChange = (currentIndex: number) => {
    setCurrentIndex(currentIndex + 1);
  };

  const renderBanners = () => {
    return bannerList.map((banner, index) => {
      if ("feedpost" in banner) {
        let title = banner.feedpost.content.title;

        if (title.includes("{}")) {
          title = title.split("{}");
        }

        const ServerBannerNoticeProps = {
          onClick: () => onClickNoticeFeed(banner),
          title: title,
          bannerId: banner.feedpost.feedpost_id,
        };

        return <ServerBannerNotice {...ServerBannerNoticeProps} />;
      } else {
        const StaticBannerNoticeProps = {
          title: banner.text,
          bannerImage: banner.image,
          textColor: banner.textColor,
          onClick: banner.onClick,
        };
        return <StaticBannerNotice {...StaticBannerNoticeProps} />;
      }
    });
  };

  return (
    <BannerContainer>
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        dynamicHeight={true}
        showStatus={false}
        onChange={onChange}
      >
        {renderBanners()}
      </Carousel>
      <IndexIndicator>
        <ShowTranslation
          style={{ marginRight: 3 }}
          weight={"r"}
          size={10}
          color={colors.white}
        >
          {currentIndex}
        </ShowTranslation>
        <ShowTranslation
          weight={"r"}
          size={10}
          color={"rgba(255, 255, 255, 0.7)"}
        >
          {`/ ${bannerList.length}`}
        </ShowTranslation>
      </IndexIndicator>
    </BannerContainer>
  );
};

export default BannerNotice;

const StaticBannerNotice = ({
  title,
  bannerImage,
  textColor,
  onClick,
}: {
  title: string;
  bannerImage: string;
  textColor: string;
  onClick: () => void;
}) => {
  const bannerTextRef = useRef<HTMLDivElement>(null);
  const [isTruncate, setIsTruncate] = useState(false);

  useEffect(() => {
    if (bannerTextRef.current) {
      const height = bannerTextRef.current.offsetHeight;
      const lines = height / 17;

      if (lines > 1) {
        setIsTruncate(true);
      }
    }
  }, []);

  return (
    <BannerWrapper key={`banner-id-${title}`} onClick={onClick}>
      <BannerImage src={bannerImage} />
      <BannerTextWrapper>
        <ShowTranslation
          ref={bannerTextRef}
          style={{
            width: "100%",
            maxHeight: 34,
            alignItems: "center",
            textAlign: "start",
            lineBreak: "anywhere",
            lineHeight: "17px",
          }}
          size={14}
          weight={"b"}
          color={textColor}
          numberOfLines={isTruncate ? 2 : 0}
        >
          {title}
        </ShowTranslation>
      </BannerTextWrapper>
    </BannerWrapper>
  );
};

const ServerBannerNotice = ({
  onClick,
  title,
  bannerId,
}: {
  onClick: () => void;
  title: string;
  bannerId: string;
}) => {
  const bannerTextRef = useRef<HTMLDivElement>(null);
  const [isTruncate, setIsTruncate] = useState(false);

  useEffect(() => {
    if (bannerTextRef.current) {
      const height = bannerTextRef.current.offsetHeight;

      const lines = height / 17;

      if (lines > 1) {
        setIsTruncate(true);
      }
    }
  }, []);

  return (
    <BannerWrapper key={`banner-id-${bannerId}`} onClick={onClick}>
      <BannerImage src={orangeBanner} />
      <BannerTextWrapper>
        <ShowTranslation
          ref={bannerTextRef}
          style={{
            width: "100%",
            maxHeight: 34,
            alignItems: "center",
            textAlign: "start",
            lineBreak: "anywhere",
            lineHeight: "17px",
          }}
          size={14}
          weight={"b"}
          numberOfLines={isTruncate ? 2 : 0}
        >
          {title}
        </ShowTranslation>
      </BannerTextWrapper>
    </BannerWrapper>
  );
};
