import styled from "styled-components";
import colors from "../../styles/colors";
import fontWeight from "../../styles/fontWeights";
import backIcon from "../../assets/images/btnBack20@3x.png";
import closeIcon from "../../assets/images/ic20CloseGray900@3x.png";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  position: relative;
`;

export const BackIcon = styled.img.attrs({
  src: `${backIcon}`,
})`
  width: 20px;
  height: 20px;
`;

export const CloseIcon = styled.img.attrs({
  src: `${closeIcon}`,
})`
  width: 20px;
  height: 20px;
`;

export const HeaderRight = styled.div`
  display: flex;
  z-index: 10;
`;

export const HeaderLeft = styled.div`
  display: flex;
  z-index: 10;
`;

export const HeaderTitle = styled.div`
  display: flex;
  position: absolute;
  width: calc(100% - 40px);
  justify-content: center;
`;

export const Button = styled.button`
  padding: 4px 12px 4px 12px;
  background-color: ${colors.Blue700};
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  font-weight: ${fontWeight.bold};
  font-size: 15px;
  color: ${colors.white};
`;
