import {
  Wrapper,
  BackIcon,
  CloseIcon,
  Button,
  HeaderLeft,
  HeaderRight,
  HeaderTitle,
} from "./styles";

import { ShowTranslation } from "../../components";
import colors from "../../styles/colors";

type headerLeftOfRight =
  | "back"
  | "close"
  | string
  | JSX.Element
  | null
  | undefined;

type headerTitle = string | JSX.Element | undefined;

interface IProps {
  headerLeft?: headerLeftOfRight;
  headerTitle?: headerTitle;
  headerRight?: headerLeftOfRight;
  buttonText?: string;
  onClickLeft: () => void;
  onClickRight: () => void;
}

const back = "back";
const close = "close";
const button = "button";

const CommonHeader = ({
  headerLeft,
  headerTitle,
  headerRight,
  buttonText,
  onClickLeft,
  onClickRight,
}: IProps) => {
  const renderHeaderLeft = ({
    headerLeft,
    onClickLeft,
  }: {
    headerLeft: headerLeftOfRight;
    onClickLeft: () => void;
  }) => {
    return (
      <HeaderLeft>
        {headerLeft === close ? (
          <CloseIcon onClick={onClickLeft} />
        ) : headerLeft === back ? (
          <BackIcon onClick={onClickLeft} />
        ) : headerLeft === button ? (
          <Button onClick={onClickLeft}>{buttonText}</Button>
        ) : (
          headerLeft
        )}
      </HeaderLeft>
    );
  };

  const renderHeaderTitle = ({ headerTitle }: { headerTitle: headerTitle }) => {
    return <HeaderTitle>{headerTitle}</HeaderTitle>;
  };

  const renderHeaderRight = ({
    headerRight,
    onClickRight,
  }: {
    headerRight: headerLeftOfRight;
    onClickRight: () => void;
  }) => {
    return (
      <HeaderRight onClick={onClickRight}>
        {headerRight === close ? (
          <CloseIcon />
        ) : headerRight === back ? (
          <BackIcon />
        ) : typeof headerRight === "string" ? (
          <ShowTranslation size={15} weight={"r"} color={colors.gray600}>
            {headerRight}
          </ShowTranslation>
        ) : (
          headerRight
        )}
      </HeaderRight>
    );
  };

  return (
    <Wrapper>
      {renderHeaderLeft({ headerLeft, onClickLeft })}
      {renderHeaderTitle({ headerTitle })}
      {renderHeaderRight({ headerRight, onClickRight })}
    </Wrapper>
  );
};

CommonHeader.defaultProps = {
  headerLeft: "back",
  headerTitle: "",
  headerRight: null,
  buttonText: "",
  onClickLeft: () => {},
  onClickRight: () => {},
};

export default CommonHeader;
