import { forwardRef } from "react";
import { CSSProperties } from "styled-components";
import colors from "../../styles/colors";
import fontWeights from "../../styles/fontWeights";
import getTranslation from "../../common/utils/getTranslation";

interface IProps {
  color?: string;
  size?: number | string;
  weight?: string;
  highlightSize?: number;
  highlightWeight?: string;
  highlightTextDecoLine?: string;
  style?: CSSProperties;
  numberOfLines?: number;
  children: number[] | number | string | string[] | JSX.Element;
}

const ShowTranslation = (
  {
    color = colors.gray900,
    size,
    weight = "m",
    highlightSize,
    highlightWeight,
    highlightTextDecoLine,
    style,
    numberOfLines,
    children,
    ...rest
  }: IProps,
  ref?: any
) => {
  let renderText;
  let textWeight: number | undefined;
  let highlightTextWeight: number | undefined;

  switch (weight) {
    case "l":
      textWeight = fontWeights.light;
      break;
    case "r":
      textWeight = fontWeights.regular;
      break;
    case "m":
      textWeight = fontWeights.medium;
      break;
    case "b":
      textWeight = fontWeights.bold;
      break;
  }

  switch (highlightWeight) {
    case "l":
      highlightTextWeight = fontWeights.light;
      break;
    case "r":
      highlightTextWeight = fontWeights.regular;
      break;
    case "m":
      highlightTextWeight = fontWeights.medium;
      break;
    case "b":
      highlightTextWeight = fontWeights.bold;
      break;
  }

  if (Array.isArray(children)) {
    renderText = children.map((item, index) => {
      if (item && typeof item === "string" && item.includes("/")) {
        const colorText = item.split("/");

        let fontColor;

        switch (colorText[0]) {
          case "PRIMARY":
            fontColor = colors.PRIMARY;
            break;
          case "gray900":
            fontColor = colors.gray900;
            break;
          case "gray800":
            fontColor = colors.gray800;
            break;
          case "gray700":
            fontColor = colors.gray700;
            break;
          case "gray600":
            fontColor = colors.gray600;
            break;
          case "gray500":
            fontColor = colors.gray500;
            break;
          case "gray400":
            fontColor = colors.gray400;
            break;
          case "gray300":
            fontColor = colors.gray300;
            break;
          case "gray200":
            fontColor = colors.gray200;
            break;
          case "gray100":
            fontColor = colors.gray100;
            break;
          case "white":
            fontColor = colors.white;
            break;
          case "neonGreen":
            fontColor = colors.neonGreen;
            break;
          case "CNTMint":
            fontColor = colors.CNTMint;
            break;
          case "Blue700":
            fontColor = colors.Blue700;
            break;
          case "CNTDarkGreen":
            fontColor = colors.CNTDarkGreen;
            break;
          case "CNTMidGreen":
            fontColor = colors.CNTMidGreen;
            break;
          case "CNTLightGreen":
            fontColor = colors.CNTLightGreen;
            break;
          case "CNTPurple":
            fontColor = colors.CNTPurple;
            break;
          case "lightPurple":
            fontColor = colors.lightPurple;
            break;
          case "callingGreen":
            fontColor = colors.callingGreen;
            break;
          case "notiRed":
            fontColor = colors.notiRed;
            break;
          case "bannerBlue":
            fontColor = colors.bannerBlue;
            break;
          case "toastGray":
            fontColor = colors.toastGray;
            break;
          case "linkBlue":
            fontColor = colors.linkBlue;
            break;
        }
        return (
          <span
            key={`translation-id-${index}`}
            style={Object.assign({
              textDecorationLine: highlightTextDecoLine
                ? highlightTextDecoLine
                : "none",
              fontSize: `${highlightSize}px`
                ? `${highlightSize}px`
                : `${size}px`,
              fontWeight: highlightTextWeight
                ? highlightTextWeight
                : textWeight,
              color: fontColor ? fontColor : color,
            })}
          >
            {colorText[1]}
          </span>
        );
      } else {
        return item;
      }
    });
  } else {
    renderText = children;
  }

  return (
    <div
      ref={ref}
      {...rest}
      style={Object.assign(
        {
          display: "flex",
          color: color,
          fontWeight: textWeight,
          fontSize: `${size}px`,
        },
        { ...style },
        numberOfLines
          ? {
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: `${numberOfLines}`,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }
          : {}
      )}
    >
      {renderText}
    </div>
  );
};

export const SampleText = () => {
  return (
    <ShowTranslation
      color={colors.CNTPurple}
      size={20}
      weight="l"
      highlightSize={30}
      highlightWeight="b"
      highlightTextDecoLine="underline"
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        border: `1px solid ${colors.linkBlue}`,
        backgroundColor: "whitesmoke",
        margin: "20px",
        padding: "10px",
      }}
    >
      {getTranslation(
        "동경asdfkjasdflkjadfla flakjasdfkjasflkadjlkadfjalkdj {}CNTMint/{t}{} 북위 {}CNTMint/{s}{}",
        {
          "{t}": 132,
          "{s}": 37,
        }
      )}
    </ShowTranslation>
  );
};

export default forwardRef(ShowTranslation);
