import styled, { keyframes } from "styled-components";
import { ImageSourceButton } from "../../styles/commonStyles";
import scrollToTop from "../../assets/images/btn47ArrowUp@3x.png";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const ScrollToTopIcon = styled(ImageSourceButton).attrs({
  source: scrollToTop,
  width: 47,
  height: 47,
})`
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 20px;
  visibility: ${(props: { visible: boolean }) =>
    props.visible ? "visible" : "hidden"};
  z-index: 15;
  animation: ${(props: { visible: boolean }) =>
      props.visible ? fadeIn : fadeOut}
    0.2s ease-out;
  transition: visibility 0.2s ease-out;
`;

// text-decoration: none;
// display: flex;
// color: ${colors.white};
// font-weight: ${fontWeight.bold};
// padding: 0px 20px;
// height: 47px;
// border-radius: 25px;
// align-items: center;
// justify-content: center;
// z-index: 10;
// position: fixed;
// bottom: 20px;
// right: 20px;
// background-color: rgba(0, 194, 176, 0.95);
